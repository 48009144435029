import React from "react";

import {useSelector} from "react-redux";

import {
    VStack,
    Text,
    Center,
    Spinner
} from "@chakra-ui/react";

import {useQuery} from "@tanstack/react-query";

import {RootState} from "./store";

import {CustomerDiagnoseRequestDto} from "./api/dtos";
import DataService from "./api/data-service";

import DiagnosticError from "./diagnostic-error";

function DiagnosticCustomerSummary() {
    const questionnaire = useSelector((state: RootState) => state.questionnaire);

    const {isLoading, isError} = useQuery<BlobPart, Error>(
        ["customer-diagnose"],
        () => {
            const diagnoseRequestDto: CustomerDiagnoseRequestDto = {
                firstName: questionnaire.info.firstName,
                lastName: questionnaire.info.lastName,
                company: questionnaire.info.company,
                email: questionnaire.info.email,
                phone: questionnaire.info.phone,
                answer1: questionnaire.answer1!,
                answer2: questionnaire.answer2!,
                answer3: questionnaire.answer3!,
                answer4: questionnaire.answer4!,
                answer5: questionnaire.answer5!,
                answer6: questionnaire.answer6!,
                answer7: questionnaire.answer7!,
                answer8: questionnaire.answer8!,
                answer9: questionnaire.answer9!,
                answer10: questionnaire.answer10!,
                answer11: questionnaire.answer11!,
                answer12: questionnaire.answer12!,
            }

            return DataService.customerDiagnose(diagnoseRequestDto);
        },
        {
            retry: 5,
        }
    );

    if (isLoading) {
        return (
            <Center>
                <VStack>
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='orange.500'
                        size='xl'
                    />
                    <Text>Analysing results...</Text>
                </VStack>
            </Center>
        );
    }

    if (isError) {
        return (
            <DiagnosticError/>
        );
    }

    return (
        <Text textAlign={"center"}>
            Thank you for completing the Rapid Team Effectiveness Diagnostic Report. Your Diagnostic Report will shortly be emailed to you at the address you provided. If you have any questions
            about the report and/or the recommendations, please do reply to us and we’ll offer support. Thank you.
        </Text>
    );
}

export default DiagnosticCustomerSummary;
