import {Box, Center, Container, Heading, useMediaQuery, VStack} from "@chakra-ui/react";
import {Step, Steps, useSteps} from 'chakra-ui-steps';

import {useParams} from "react-router-dom";

import QuestionGroupPersonalInfo from "./question-group-personal-info";
import QuestionGroup1 from "./question-group-1";
import QuestionGroup2 from "./question-group-2";
import QuestionGroup3 from "./question-group-3";
import QuestionGroup4 from "./question-group-4";
import QuestionGroup5 from "./question-group-5";
import QuestionGroup6 from "./question-group-6";
import QuestionGroup7 from "./question-group-7";
import QuestionGroup8 from "./question-group-8";
import QuestionGroup9 from "./question-group-9";
import QuestionGroup10 from "./question-group-10";
import QuestionGroup11 from "./question-group-11";
import QuestionGroup12 from "./question-group-12";
import DiagnosticGroupSummary from "./diagnostic-group-summary";
import React, {useEffect} from "react";
import ReactGA from "react-ga4";

const Questionnaire = () => {
    const {groupCode} = useParams<{ groupCode: string }>();

    const {nextStep, prevStep, activeStep} = useSteps({
        initialStep: 0,
    });

    useEffect(() => {
        const currentStep = activeStep + 1;

        const page = currentStep === 0 ? "Group Diagnostic" : "Group Diagnostic - " + currentStep;

        ReactGA.send({
            hitType: "pageview",
            page: page,
            title: page
        });
    }, [activeStep]);

    const steps = [
        {label: 'Personal Details', content: <QuestionGroupPersonalInfo groupCode={groupCode} nextAction={nextStep}/>},
        {label: 'Integrity', content: <QuestionGroup1 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Humanity', content: <QuestionGroup2 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Candor', content: <QuestionGroup3 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Cohesion', content: <QuestionGroup4 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Ecosystem', content: <QuestionGroup5 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Accountability', content: <QuestionGroup6 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Learning', content: <QuestionGroup7 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Resilience', content: <QuestionGroup8 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Outcomes', content: <QuestionGroup9 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Geography', content: <QuestionGroup10 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Care', content: <QuestionGroup11 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Cohesion', content: <QuestionGroup12 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Results', content: <DiagnosticGroupSummary/>},
    ];

    const [isLargerThan800] = useMediaQuery('(min-width: 800px)')

    return (
        <VStack spacing={7}>
            <Center bg={'#1E295C'} w={'100%'}>
                <VStack mt={5} mb={5}>
                    <Heading size={'3xl'} color={'white'}>Redefine Winning Client Team Diagnostic</Heading>
                    <Heading size={"xl"} color={'white'}>You are submitting for Group Code: {groupCode}</Heading>
                </VStack>
            </Center>

            <Container minW={['1xl', '1xl', '3xl', '4xl', '6xl']}>
                <Steps activeStep={activeStep} size='md' colorScheme={'orange'} responsive={false}
                       sx={{
                           display: isLargerThan800 ? "flex" : "none"
                       }}>
                    {steps.map(({label, content}, index) =>
                        (
                            <Step label={""} key={index}>
                                <Box m={5}>
                                    {content}
                                </Box>
                            </Step>
                        ))}
                </Steps>
            </Container>
        </VStack>
    );
};

export default Questionnaire;
