import React from "react";

import {useDispatch, useSelector} from "react-redux";

import {Answer, updateAnswer11} from "./questionnaire-slice";
import Question from "./question";
import {QuestionProps} from "./question-props";
import {RootState} from "./store";

function QuestionGroup11({prevAction, nextAction}: QuestionProps) {
    const questionnaire = useSelector((state: RootState) => state.questionnaire);
    const dispatch = useDispatch()

    const next = (answer: Answer) => {
        dispatch(updateAnswer11(answer));

        nextAction();
    }

    return (
        <Question category={"Care"}
                  text={"We all prioritize care for our first team"}
                  whyImportantText={"Does your team respect and value individual contributions or just focus on the loudest voices in the room? Do you get to know your peers by being vulnerable and connecting at a personal level, too?"}
                  whatBadLooksLikeText={["Not knowing what is important to peers at a personal and professional level", "Not assuring all voices are heard", "Disregarding input and stifling involvement"]}
                  whatGreatLooksLikeText={["Getting to know peers and colleagues so you understand them as whole human beings", "Having a safe space with a ‘first team’ to share what is important to one another", "Offering timely support in the moment to show you care"]}
                  initialAnswer={questionnaire.answer11}
                  prevAction={prevAction}
                  nextAction={next}
        />
    );
}

export default QuestionGroup11;
