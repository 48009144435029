import React from "react";

import {
    AnnotationDirective,
    Annotations, AnnotationsDirective,
    AxesDirective,
    AxisDirective, Gradient,
    IAxisLabelRenderEventArgs,
    LinearGaugeComponent,
    PointerDirective,
    PointersDirective, RangeDirective, RangesDirective
} from "@syncfusion/ej2-react-lineargauge";

import {
    Heading
} from "@chakra-ui/react";

import {TeqGroupDto} from "./api/dtos";
import {Inject} from "@syncfusion/ej2-react-charts";
import {LinearGradientModel} from "@syncfusion/ej2-lineargauge/src/linear-gauge/axes/gradient-model";

const axisLabelRender = (args: IAxisLabelRenderEventArgs): void => {
    console.log(args.text);
    if (args.text === "91")
        args.text = "A";
    else if (args.text === "75")
        args.text = "B";
    else if (args.text === "58")
        args.text = "C";
    else if (args.text === "41")
        args.text = "D";
    else if (args.text === "25")
        args.text = "E";
    else if (args.text === "8")
        args.text = "F";
    else
        args.text = "";
}

const teqToNumber = (teq: string): number => {
    console.log(teq);
    switch (teq) {
        case "A":
            return 98;
        case "B":
            return 75;
        case "C":
            return 58;
        case "D":
            return 41;
        case "E":
            return 25;
        case "F":
            return 8;
        default:
            return 0;
    }
}

const rangeLinearGradient: LinearGradientModel = {
    startValue: "0%",
    endValue: "100%",
    colorStop: [
        {color: "#dd3800", offset: "0%", opacity: 1},
        {color: "#ff4100", offset: "25%", opacity: 1},
        {color: "#ffba00", offset: "50%", opacity: 1},
        {color: "#ffdf10", offset: "75%", opacity: 1},
        {color: "#64be00", offset: "100%", opacity: 1}
    ]
};

export default function TeqGroupGauge(props: { teq: TeqGroupDto }) {
    const base = teqToNumber(props.teq.base);
    const value = teqToNumber(props.teq.teq);


    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <LinearGaugeComponent axisLabelRender={axisLabelRender} background={'transparent'} orientation='Horizontal' width='500px' height='320px'
                                  container={{width: 116, type: 'RoundedRectangle', border: {width: 5}}} style={{fontFamily: "AktivGrotesk"}}>
                <Inject services={[Annotations, Gradient]}/>
                <AxesDirective>
                    <AxisDirective minimum={0} maximum={100} line={{width: 0}} minorTicks={{interval: 16.66, height: 0, offset: 8.33}} majorTicks={{interval: 1, height: 0, offset: 8.33}}
                                   labelStyle={{offset: -10, font: {size: "18px", fontFamily: "inherit"}}}>
                        <PointersDirective>
                            <PointerDirective width={0}>
                            </PointerDirective>
                        </PointersDirective>
                        <RangesDirective>
                            <RangeDirective start={2} end={98} startWidth={100} endWidth={100} linearGradient={rangeLinearGradient} offset={108}>
                            </RangeDirective>
                            <RangeDirective start={2} end={base} startWidth={25} endWidth={25} color={"grey"} offset={108}>
                            </RangeDirective>
                            <RangeDirective start={2} end={value} startWidth={25} endWidth={25} color={"#002060"} offset={60}>
                            </RangeDirective>
                        </RangesDirective>
                    </AxisDirective>
                </AxesDirective>
                <AnnotationsDirective>
                    <AnnotationDirective content='<div style="width: 16px;height: 74px;border: 5px solid #bfbfbf;margin-left:320px;margin-top:115px;border-radius: 6px;" />'
                                         axisIndex={0}
                                         axisValue={60}
                                         x={0} zIndex='1'
                                         y={0}>
                    </AnnotationDirective>
                </AnnotationsDirective>
            </LinearGaugeComponent>
            <Heading size={'4xl'}>{props.teq.teq}</Heading>
        </div>
    );
}
