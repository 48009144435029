import React, {ChangeEvent, useState} from "react";

import {Button, Input} from "@chakra-ui/react";

import {TpiCustomerDto} from "./api/dtos";

import TpiCustomerGauge from "./tpi-customer-gauge";

function AutomatedTpiCustomerResult() {
    const [value, setValue] = useState<string>("");
    const [response, setResponse] = useState<TpiCustomerDto | null>(null);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        setValue(inputValue);
    }

    const onSubmit = async () => {
        if (value) {
            const parsedResponse: TpiCustomerDto = JSON.parse(value);

            setResponse(parsedResponse);
        }
    }

    if (!response) {
        return (
            <form>
                <Input value={value}
                       onChange={handleChange}
                       name="input"/>

                <Button name="submit" onClick={onSubmit} type="submit">Submit</Button>
            </form>
        );
    } else {
        return (
            <div id="diagnostic-results">
                <TpiCustomerGauge tpi={response}/>
            </div>
        )
    }
}


export default AutomatedTpiCustomerResult;
