import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'

export type Answer =
    'Strongly Disagree' |
    'Disagree' |
    'Agree' |
    'Strongly Agree';

export interface QuestionnaireInfo {
    firstName: string,
    lastName: string,
    company: string,
    email: string,
    phone: string,
}

export interface QuestionnaireState {
    groupCode: string | null,
    info: QuestionnaireInfo,
    answer1: Answer | null,
    answer2: Answer | null,
    answer3: Answer | null,
    answer4: Answer | null,
    answer5: Answer | null,
    answer6: Answer | null,
    answer7: Answer | null,
    answer8: Answer | null,
    answer9: Answer | null,
    answer10: Answer | null,
    answer11: Answer | null,
    answer12: Answer | null,
}

const initialState: QuestionnaireState = {
    groupCode: null,
    info: {
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        phone: "",
    },
    answer1: null,
    answer2: null,
    answer3: null,
    answer4: null,
    answer5: null,
    answer6: null,
    answer7: null,
    answer8: null,
    answer9: null,
    answer10: null,
    answer11: null,
    answer12: null,
}

export const questionnaireSlice = createSlice({
    name: 'questionnaire',
    initialState,
    reducers: {
        updateGroupCode: (state, action: PayloadAction<string>) => {
            state.groupCode = action.payload;
        },
        updateInfo: (state, action: PayloadAction<QuestionnaireInfo>) => {
            state.info = action.payload
        },
        updateAnswer1: (state, action: PayloadAction<Answer>) => {
            state.answer1 = action.payload
        },
        updateAnswer2: (state, action: PayloadAction<Answer>) => {
            state.answer2 = action.payload
        },
        updateAnswer3: (state, action: PayloadAction<Answer>) => {
            state.answer3 = action.payload
        },
        updateAnswer4: (state, action: PayloadAction<Answer>) => {
            state.answer4 = action.payload
        },
        updateAnswer5: (state, action: PayloadAction<Answer>) => {
            state.answer5 = action.payload
        },
        updateAnswer6: (state, action: PayloadAction<Answer>) => {
            state.answer6 = action.payload
        },
        updateAnswer7: (state, action: PayloadAction<Answer>) => {
            state.answer7 = action.payload
        },
        updateAnswer8: (state, action: PayloadAction<Answer>) => {
            state.answer8 = action.payload
        },
        updateAnswer9: (state, action: PayloadAction<Answer>) => {
            state.answer9 = action.payload
        },
        updateAnswer10: (state, action: PayloadAction<Answer>) => {
            state.answer10 = action.payload
        },
        updateAnswer11: (state, action: PayloadAction<Answer>) => {
            state.answer11 = action.payload
        },
        updateAnswer12: (state, action: PayloadAction<Answer>) => {
            state.answer12 = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    updateGroupCode,
    updateInfo,
    updateAnswer1,
    updateAnswer2,
    updateAnswer3,
    updateAnswer4,
    updateAnswer5,
    updateAnswer6,
    updateAnswer7,
    updateAnswer8,
    updateAnswer9,
    updateAnswer10,
    updateAnswer11,
    updateAnswer12
} = questionnaireSlice.actions

export default questionnaireSlice.reducer