import React, {useEffect} from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import ReactGA from 'react-ga4';

import "./app.css";

import QuestionnaireCustomer from "./questionnaire-customer";
import QuestionnaireGroup from "./questionnaire-group";

import AutomatedTpiCustomerResult from "./automated-tpi-customer-result";
import AutomatedViCustomerResult from "./automated-vi-customer-result";
import AutomatedTeqCustomerResult from "./automated-teq-customer-result";

import AutomatedTpiGroupResult from "./automated-tpi-group-result";
import AutomatedViGroupResult from "./automated-vi-group-result";
import AutomatedTeqGroupResult from "./automated-teq-group-result";
import AutomatedTpiGroupDetailResult from "./automated-tpi-group-detail-result";
import AutomatedTpiTeamAssessmentGroupResult from "./automated-tpi-team-assessment-group-result";
import AutomatedTeqTeamAssessmentGroupResult from "./automated-teq-team-assessment-group-result";

function App() {
    useEffect(() => {
        ReactGA.initialize("G-5M45B9JQ4K");
    }, []);
    return (
        <Router>
            <Switch>
                <Route exact={true} path={"/"}>
                    <QuestionnaireCustomer/>
                </Route>
                <Route exact={true} path={"/group/:groupCode"}>
                    <QuestionnaireGroup/>
                </Route>
                <Route path={"/automated-tpi-customer-result"}>
                    <AutomatedTpiCustomerResult/>
                </Route>
                <Route path={"/automated-vi-customer-result"}>
                    <AutomatedViCustomerResult/>
                </Route>
                <Route path={"/automated-teq-customer-result"}>
                    <AutomatedTeqCustomerResult/>
                </Route>
                <Route path={"/automated-tpi-group-result"}>
                    <AutomatedTpiGroupResult/>
                </Route>
                <Route path={"/automated-tpi-detail-group-result"}>
                    <AutomatedTpiGroupDetailResult/>
                </Route>
                <Route path={"/automated-vi-group-result"}>
                    <AutomatedViGroupResult/>
                </Route>
                <Route path={"/automated-teq-group-result"}>
                    <AutomatedTeqGroupResult/>
                </Route>
                <Route path={"/automated-tpi-team-assessment-group-result"}>
                    <AutomatedTpiTeamAssessmentGroupResult/>
                </Route>
                <Route path={"/automated-teq-team-assessment-group-result"}>
                    <AutomatedTeqTeamAssessmentGroupResult/>
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
