import React from "react";

import {useDispatch, useSelector} from "react-redux";

import {Answer, updateAnswer1} from "./questionnaire-slice";
import Question from "./question";
import {QuestionProps} from "./question-props";
import {RootState} from "./store";

function QuestionGroup1({prevAction, nextAction}: QuestionProps) {
    const questionnaire = useSelector((state: RootState) => state.questionnaire);
    const dispatch = useDispatch();

    const next = (answer: Answer) => {
        dispatch(updateAnswer1(answer));

        nextAction();
    }

    return (
        <Question category={"Integrity"}
                  text={"We all do the right thing, even when no-one is watching"}
                  whyImportantText={"Teams that demonstrate high levels of integrity achieve accelerated and elevated success. Does yours?"}
                  whatBadLooksLikeText={["Being consistently late", "Taking unethical action", "Sharing misleading information", "Marginalizing colleagues, customers, or partners"]}
                  whatGreatLooksLikeText={["Taking action, even if it’s not your responsibility", "Owning the team’s success beyond your area of expertise", "Being reliable and trustworthy"]}
                  initialAnswer={questionnaire.answer1}
                  prevAction={prevAction}
                  nextAction={next}
        />
    );
}

export default QuestionGroup1;
