import React from "react";

import {useDispatch} from "react-redux";

import {
    Card,
    CardFooter,
    CardHeader,
    Center,
    Heading,
    HStack
} from "@chakra-ui/react";

import {Formik} from "formik";
import {
    SubmitButton
} from "formik-chakra-ui";
import * as Yup from "yup";

import {updateGroupCode} from "./questionnaire-slice";

type StartProps = {
    groupCode: string,
    nextAction: () => void
}

function QuestionGroupPersonalInfo({groupCode, nextAction}: StartProps) {
    const dispatch = useDispatch()

    const initialValues = {};

    const validationSchema = Yup.object({});

    const onSubmit = () => {
        dispatch(updateGroupCode(groupCode))

        nextAction();
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({handleSubmit}) => (
                <Card>
                    <CardHeader>
                        <Center>
                            <Heading size={"xl"} mt={7}>You are submitting for Group Code: {groupCode}</Heading>
                        </Center>
                    </CardHeader>

                    <CardFooter justify={'center'}>
                        <HStack justify={'center'}>
                            <SubmitButton onClick={() => handleSubmit()} variant={'outline'} colorScheme={'orange'}>Next</SubmitButton>
                        </HStack>
                    </CardFooter>
                </Card>
            )}
        </Formik>
    );
}

export default QuestionGroupPersonalInfo;
