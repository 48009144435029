import * as React from "react";

import {
    Category,
    ChartComponent,
    Inject,
    SeriesCollectionDirective,
    SeriesDirective,
    ColumnSeries,
    DataLabel,
    Legend,
    IAxisLabelRenderEventArgs
} from '@syncfusion/ej2-react-charts';

import {TpiTeamAssessmentGroupDto} from "./api/dtos";

const axisLabelRender = (args: IAxisLabelRenderEventArgs): void => {
    console.log(args.text);

    switch (args.text) {
        case "Q1":
            args.text = "Integrity";
            break;
        case "Q2":
            args.text = "Humanity";
            break;
        case "Q3":
            args.text = "Candor";
            break;
        case "Q4":
            args.text = "Co-Creation";
            break;
        case "Q5":
            args.text = "Ecosystem";
            break;
        case "Q6":
            args.text = "Accountability";
            break;
        case "Q7":
            args.text = "Learning";
            break;
        case "Q8":
            args.text = "Resilience";
            break;
        case "Q9":
            args.text = "Outcomes";
            break;
        case "Q10":
            args.text = "Geography";
            break;
        case "Q11":
            args.text = "Care";
            break;
        case "Q12":
            args.text = "Potential";
            break;
        default:
            break;
    }
}

const colors: string[] = [
    "#1D2A59FF",
    "#D05C27FF",
    "grey"
];

const columnWidth: number[] = [
    0.5,
    0.4,
    0.3
];

export default function TpiTeamAssessmentGroupChart(props: { tpi: TpiTeamAssessmentGroupDto }) {

    const sessions = props.tpi.sessions;

    const maxPotential = {
        sessionName: "Max Potential",
        ordinal: 0,
        data: [
            {question: 'Q1', score: 100},
            {question: 'Q2', score: 100},
            {question: 'Q3', score: 100},
            {question: 'Q4', score: 100},
            {question: 'Q5', score: 100},
            {question: 'Q6', score: 100},
            {question: 'Q7', score: 100},
            {question: 'Q8', score: 100},
            {question: 'Q9', score: 100},
            {question: 'Q10', score: 100},
            {question: 'Q11', score: 100},
            {question: 'Q12', score: 100}
        ]
    };

    const series = [maxPotential, ...sessions]
        .sort(x => x.ordinal)
        .map((x, i) => ({...x, question: "a", color: colors[i], columnWidth: columnWidth[i]}));

    console.log(series);

    return (
        <ChartComponent id='charts' style={{textAlign: "center"}}
                        axisLabelRender={axisLabelRender}
                        primaryXAxis={{
                            valueType: 'Category',
                            majorTickLines: {width: 0},
                            minorTickLines: {width: 0},
                            interval: 1,
                            majorGridLines: {width: 0},
                            titleStyle: {fontFamily: "AktivGrotesk"},
                            labelStyle: {fontFamily: "AktivGrotesk"}
                        }}
                        primaryYAxis={{
                            minimum: 0,
                            maximum: 100,
                            majorTickLines: {width: 0},
                            lineStyle: {width: 0},
                            title: 'Score',
                            titleStyle: {fontFamily: "AktivGrotesk"},
                            labelStyle: {fontFamily: "AktivGrotesk"}
                        }}
                        chartArea={{border: {width: 0}}}
                        enableSideBySidePlacement={false}
                        title='Team Cohesion Commitments'
                        titleStyle={{
                            fontFamily: "AktivGrotesk"
                        }}
                        tooltip={{enable: true, shared: true}} width={'100%'}
                        legendSettings={{
                            textStyle: {fontFamily: "AktivGrotesk"}
                        }}>
            <Inject services={[ColumnSeries, DataLabel, Category, Legend]}/>
            <SeriesCollectionDirective>
                {series.map((x, i) => {
                    return (
                        <SeriesDirective key={i} dataSource={x.data} xName='question' width={2} yName='score' name={x.sessionName} type='Column' columnWidth={x.columnWidth} fill={x.color}
                                         legendShape={"Rectangle"}
                                         marker={{
                                             dataLabel: {
                                                 visible: true,
                                                 name: 'text',
                                                 angle: -90,
                                                 position: 'Top',
                                                 font: {fontFamily: "AktivGrotesk", size: "14", fontWeight: "900", color: '#ffffff'}
                                             }
                                         }}
                                         animation={{enable: false}}/>
                    )
                })}
            </SeriesCollectionDirective>
        </ChartComponent>
    )
}
