import React from "react";

import {useDispatch, useSelector} from "react-redux";

import {Answer, updateAnswer7} from "./questionnaire-slice";
import Question from "./question";
import {QuestionProps} from "./question-props";
import {RootState} from "./store";

function QuestionCustomer7({prevAction, nextAction}: QuestionProps) {
    const questionnaire = useSelector((state: RootState) => state.questionnaire);
    const dispatch = useDispatch();

    const next = (answer: Answer) => {
        dispatch(updateAnswer7(answer));

        nextAction();
    }

    return (
        <Question category={"Learning"}
                  text={"We all coach one another"}
                  whyImportantText={"The most valuable development opportunities happen in the moment and drive us to be our very best every single day. Is peer coaching a foundational aspect of your team’s development?"}
                  whatBadLooksLikeText={["Everyone else needs to change, not me", "Knowing it all; being the ‘professor’ where you believe you’re the smartest person in the room", "Not being open to feedback that can help the team get 10X outcomes"]}
                  whatGreatLooksLikeText={["We all know our development needs, and the needs of our colleagues", "We all actively pursue learning experiences individually and together", "We provide peer coaching in the moment to maximize learning and team impact"]}
                  initialAnswer={questionnaire.answer7}
                  prevAction={prevAction}
                  nextAction={next}
        />
    );
}

export default QuestionCustomer7;
