import React from "react";

import {useDispatch, useSelector} from "react-redux";

import {Answer, updateAnswer6} from "./questionnaire-slice";
import Question from "./question";
import {QuestionProps} from "./question-props";
import {RootState} from "./store";

function QuestionGroup6({prevAction, nextAction}: QuestionProps) {
    const questionnaire = useSelector((state: RootState) => state.questionnaire);
    const dispatch = useDispatch();

    const next = (answer: Answer) => {
        dispatch(updateAnswer6(answer));

        nextAction();
    }

    return (
        <Question category={"Accountability"}
                  text={"We win as one and cross the finish line together"}
                  whyImportantText={"Ideas turn to action, and action to outcomes with individual, peer-to-peer, and team accountability. Does your team cross the finish line together?"}
                  whatBadLooksLikeText={["Not delivering on your own commitments", "Saying, “It’s not my work”, “I did my part”, or ”why should I help them?”", "Letting people fail, while being thankful your work is done"]}
                  whatGreatLooksLikeText={["Doing what you say you’ll do", "Assuring everyone else also delivers on time, to quality, and to budget", "Never leaving anyone behind"]}
                  initialAnswer={questionnaire.answer6}
                  prevAction={prevAction}
                  nextAction={next}
        />
    );
}

export default QuestionGroup6;
