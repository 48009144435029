import React from "react";

import {useDispatch, useSelector} from "react-redux";

import {Answer, updateAnswer2} from "./questionnaire-slice";
import Question from "./question";
import {QuestionProps} from "./question-props";
import {RootState} from "./store";

function QuestionGroup2({prevAction, nextAction}: QuestionProps) {
    const questionnaire = useSelector((state: RootState) => state.questionnaire);
    const dispatch = useDispatch();

    const next = (answer: Answer) => {
        dispatch(updateAnswer2(answer));

        nextAction();
    }

    return (
        <Question category={"Humanity"}
                  text={"We all demonstrate the highest levels of respect for one another"}
                  whyImportantText={"Would you want to stay in a role if the team you work with doesn’t show – through what they say and how they behave – the highest levels of respect for one another every day?"}
                  whatBadLooksLikeText={["Rude, snide, or belittling comments", "Interrupting people when they speak", "Not including key people in conversations that impact them and their work"]}
                  whatGreatLooksLikeText={["Being supportive of colleagues", "Coaching peers to a better path, if there is an alternative to their current course of action", "Rapidly discussing and resolving issues as they arise"]}
                  initialAnswer={questionnaire.answer2}
                  prevAction={prevAction}
                  nextAction={next}
        />
    );
}

export default QuestionGroup2;
