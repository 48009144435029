import * as React from "react";

import {
    Category,
    ChartComponent,
    Inject,
    SeriesCollectionDirective,
    SeriesDirective,
    ColumnSeries,
    DataLabel,
    Legend,
    IAxisLabelRenderEventArgs
} from '@syncfusion/ej2-react-charts';

import {TeqTeamAssessmentGroupDto} from "./api/dtos";

const axisLabelRender = (args: IAxisLabelRenderEventArgs): void => {
    console.log(args.text);

    args.text = numberToLetter(parseInt(args.text));
}

const numberToLetter = (num: number): string => {
    switch (num) {
        case 91:
            return "A";
        case 75:
            return "B";
        case 58:
            return "C";
        case 41:
            return "D";
        case 25:
            return "E";
        case 8:
            return "F";
        default:
            return "";
    }
}

const teqToNumber = (teq: string): number => {
    console.log(teq);
    switch (teq) {
        case "A":
            return 98;
        case "B":
            return 75;
        case "C":
            return 58;
        case "D":
            return 41;
        case "E":
            return 25;
        case "F":
            return 8;
        default:
            return 0;
    }
}

const colors: string[] = [
    "#1D2A59FF",
    "#D05C27FF",
    "grey"
];

const columnWidth: number[] = [
    0.5,
    0.4,
    0.3
];

export default function TeqTeamAssessmentGroupChart(props: { teq: TeqTeamAssessmentGroupDto }) {

    const sessions = props.teq.sessions;
    const data = sessions
        .map(x => {
            const score = teqToNumber(x.score);

            return {...x, text: x.score, score: score};
        })
        .slice();

    const maxPotential = {sessionName: "Max Potential", ordinal: 0, score: 91, text: 'A'};

    const series = [maxPotential, ...data]
        .sort(x => x.ordinal)
        .map((x, i) => ({...x, question: "a", color: colors[i], columnWidth: columnWidth[i]}));

    console.log(series);

    return (
        <ChartComponent id='charts' style={{textAlign: "center"}}
                        width={"200px"}
                        axisLabelRender={axisLabelRender}
                        primaryXAxis={{
                            valueType: 'Category',
                            majorTickLines: {width: 0},
                            minorTickLines: {width: 0},
                            interval: 1,
                            majorGridLines: {width: 0},
                            titleStyle: {fontFamily: "AktivGrotesk"},
                            labelStyle: {fontFamily: "AktivGrotesk"}
                        }}
                        primaryYAxis={{
                            minimum: 0,
                            maximum: 91,
                            majorTickLines: {width: 0},
                            interval: 1,
                            lineStyle: {width: 0},
                            majorGridLines: {width: 0},
                            title: 'Ratio To Great',
                            titleStyle: {fontFamily: "AktivGrotesk"},
                            labelStyle: {fontFamily: "AktivGrotesk"}
                        }}
                        chartArea={{border: {width: 0}}}
                        enableSideBySidePlacement={false}
                        titleStyle={{
                            fontFamily: "AktivGrotesk"
                        }}
                        tooltip={{enable: true, shared: true}}
                        legendSettings={{
                            textStyle: {fontFamily: "AktivGrotesk"}
                        }}>
            <Inject services={[ColumnSeries, DataLabel, Category, Legend]}/>
            <SeriesCollectionDirective>
                {series.map((x, i) => {
                    return (
                        <SeriesDirective key={i} dataSource={[x]} xName='question' width={2} yName='score' name={x.sessionName} type='Column' columnWidth={x.columnWidth} fill={x.color}
                                         legendShape={"Rectangle"}
                                         marker={{
                                             dataLabel: {
                                                 visible: true,
                                                 name: 'text',
                                                 angle: -90,
                                                 position: 'Top',
                                                 font: {fontFamily: "AktivGrotesk", size: "14", fontWeight: "900", color: '#ffffff'}
                                             }
                                         }}
                                         animation={{enable: false}}/>
                    )
                })}
            </SeriesCollectionDirective>
        </ChartComponent>
    )
}

