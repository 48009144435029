import React from "react";

import {
    AxesDirective,
    AxisDirective, Gradient,
    IAxisLabelRenderEventArgs,
    LinearGaugeComponent,
    PointerDirective,
    PointersDirective, RangeDirective, RangesDirective
} from "@syncfusion/ej2-react-lineargauge";

import {Vi, ViGroupDto} from "./api/dtos";
import {LinearGradientModel} from "@syncfusion/ej2-lineargauge/src/linear-gauge/axes/gradient-model";
import {Inject} from "@syncfusion/ej2-react-circulargauge";

const axisLabelRender = (args: IAxisLabelRenderEventArgs): void => {
    console.log(args.text);
    if (args.text === "0.5")
        args.text = "High";
    else if (args.text === "0.75")
        args.text = "Medium";
    else if (args.text === "1")
        args.text = "Low";
    else
        args.text = "";
}

const getVi = (vi: Vi): number => {
    if (vi === "Low") {
        return 1;
    } else if (vi === "Medium") {
        return 0.75;
    } else if (vi === "High") {
        return 0.55;
    }

    return 0;
}

export default function ViGroupGauge(props: { vi: ViGroupDto }) {
    const baseBand = getVi(props.vi.base);
    const viBand = getVi(props.vi.vi);

    const rangeLinearGradient: LinearGradientModel = {
        startValue: "0%",
        endValue: "100%",
        colorStop: [
            { color: "#dd3800", offset: "0%", opacity: 1 },
            { color: "#ff4100", offset: "25%", opacity: 1 },
            { color: "#ffba00", offset: "50%", opacity: 1 },
            { color: "#ffdf10", offset: "75%", opacity: 1 },
            { color: "#64be00", offset: "100%", opacity: 1 }
        ]
    };

    return (
        <LinearGaugeComponent axisLabelRender={axisLabelRender} orientation="Horizontal"
                              width="550px" height="210px" background={"transparent"} style={{fontFamily: "AktivGrotesk"}}>
            <Inject services={[Gradient]}/>
            <AxesDirective>
                <AxisDirective line={{width: 5}} minorTicks={{interval: 0.25, height: 0}} majorTicks={{interval: 0.25, height: 0}}
                               labelStyle={{offset: 85, font: {size: "18px", fontFamily: "inherit"}}}
                               minimum={0.5} maximum={1} opposedPosition={false}>
                    <PointersDirective>
                        <PointerDirective width={0}>
                        </PointerDirective>
                    </PointersDirective>
                    <RangesDirective>
                        <RangeDirective start={0} end={1} startWidth={110} endWidth={60} offset={55} linearGradient={rangeLinearGradient}>
                        </RangeDirective>
                        <RangeDirective start={0} end={baseBand} startWidth={25} endWidth={25} offset={55} color={"grey"}>
                        </RangeDirective>
                        <RangeDirective start={0} end={viBand} startWidth={25} endWidth={25} offset={30} color={"#002060"}>
                        </RangeDirective>
                    </RangesDirective>
                </AxisDirective>
            </AxesDirective>
        </LinearGaugeComponent>
    );
}
