import React from "react";

import {useDispatch, useSelector} from "react-redux";

import {Answer, updateAnswer10} from "./questionnaire-slice";
import Question from "./question";
import {QuestionProps} from "./question-props";
import {RootState} from "./store";

function QuestionGroup10({prevAction, nextAction}: QuestionProps) {
    const questionnaire = useSelector((state: RootState) => state.questionnaire);
    const dispatch = useDispatch();

    const next = (answer: Answer) => {
        dispatch(updateAnswer10(answer));

        nextAction();
    }

    return (
        <Question category={"Geography"}
                  text={"We all successfully partner regardless of how and where we work"}
                  whyImportantText={"Remote work is here to stay, which means we all need to know how to work successfully in a hybrid world. Moreover, effective asynchronous work gives us the opportunity to reduce meetings and increase outcomes. Does that sound good to you and your team?"}
                  whatBadLooksLikeText={["Meetings at awkward times of the day and night", "Unnecessarily flying around the world for meetings that don’t deliver exponential value", "Failing to offer asynchronous work that empowers everyone and embraces neurodivergent needs"]}
                  whatGreatLooksLikeText={["We include remote participants as if they’re in the room with us", "We utilize asynchronous work and reduce meeting volumes, while increasing outcomes", "We use the right technology and behaviors to assure effective collaboration wherever our team members are working"]}
                  initialAnswer={questionnaire.answer10}
                  prevAction={prevAction}
                  nextAction={next}
        />
    );
}

export default QuestionGroup10;
