import React from "react";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    FormControl,
    Heading,
    HStack,
    Radio,
    RadioGroup,
    VStack, Wrap, WrapItem, SimpleGrid, Box, UnorderedList, ListItem, Divider
} from "@chakra-ui/react";

import {Answer} from "./questionnaire-slice";

type QuestionProps = {
    category: string,
    text: string,
    whyImportantText: string,
    whatBadLooksLikeText: string[],
    whatGreatLooksLikeText: string[],
    initialAnswer: Answer | null,
    prevAction: () => void,
    nextAction: (answer: Answer) => void,
}

const convertNumberToAnswer = (value: string): Answer | null => {
    switch (value) {
        case '1':
            return 'Strongly Disagree';
        case '2':
            return 'Disagree';
        case '3':
            return 'Agree';
        case '4':
            return 'Strongly Agree';
        default:
            return null;
    }
}

const convertAnswerToNumber = (answer: Answer | null): string => {
    switch (answer) {
        case 'Strongly Disagree':
            return '1';
        case 'Disagree':
            return '2';
        case 'Agree':
            return '3';
        case 'Strongly Agree':
            return '4';
        case null:
            return '0';
        default:
            return '0';
    }
}

function Question({category, text, whyImportantText, whatBadLooksLikeText, whatGreatLooksLikeText, initialAnswer, prevAction, nextAction}: QuestionProps) {
    const initialValue = convertAnswerToNumber(initialAnswer);
    const [value, setValue] = React.useState(initialValue);

    const onSubmit = () => {
        const answer = convertNumberToAnswer(value);

        if (answer) {
            nextAction(answer);
        }
    }

    return (
        <form onSubmit={onSubmit}>
            <FormControl>
                <Card>
                    <CardHeader minH={230}>
                        <VStack spacing={7}>
                            <Heading size={"2xl"} mt={7}>{category}</Heading>
                            <Heading size={"lg"} textAlign={'center'}>{text}</Heading>
                        </VStack>
                    </CardHeader>

                    <Divider/>

                    <CardBody>
                        <HStack justify={'center'}>
                            <RadioGroup onChange={setValue} value={value}>
                                <Wrap spacing={'40px'}>
                                    <WrapItem>
                                        <Radio value='1' size={'lg'} colorScheme={'orange'}>Strongly Disagree</Radio>
                                    </WrapItem>
                                    <WrapItem>
                                        <Radio value='2' size={'lg'} colorScheme={'orange'}>Disagree</Radio>
                                    </WrapItem>
                                    <WrapItem>
                                        <Radio value='3' size={'lg'} colorScheme={'orange'}>Agree</Radio>
                                    </WrapItem>
                                    <WrapItem>
                                        <Radio value='4' size={'lg'} colorScheme={'orange'}>Strongly Agree</Radio>
                                    </WrapItem>
                                </Wrap>
                            </RadioGroup>
                        </HStack>
                    </CardBody>

                    <CardFooter justify={'center'}>
                        <VStack spacing={7}>
                            <HStack justify={'center'} spacing={10}>
                                <Button variant={'outline'} colorScheme='blackAlpha' onClick={prevAction}>
                                    Back
                                </Button>
                                <Button type={"submit"} variant={'outline'} colorScheme={'orange'} disabled={value === '0'}>
                                    Next
                                </Button>
                            </HStack>

                            <Divider/>

                            <Heading size={"md"} textAlign={'center'}>Why is this important?</Heading>
                            <Heading size={"sm"} textAlign={'center'}>{whyImportantText}</Heading>

                            <SimpleGrid columns={{sm: 1, md: 2}} spacing={10}>
                                <Box>
                                    <Heading size={"sm"} textAlign={'left'}>What bad looks like:</Heading>
                                    <UnorderedList>
                                        {whatBadLooksLikeText?.map((text, index) => <ListItem key={index}>{text}</ListItem>)}
                                    </UnorderedList>
                                </Box>
                                <Box>
                                    <Heading size={"sm"} textAlign={'left'}>What great looks like:</Heading>
                                    <UnorderedList>
                                        {whatGreatLooksLikeText?.map((text, index) => <ListItem key={index}>{text}</ListItem>)}
                                    </UnorderedList>
                                </Box>
                            </SimpleGrid>
                        </VStack>
                    </CardFooter>
                </Card>
            </FormControl>
        </form>
    );
}

export default Question;
