import {ComponentStyleConfig} from "@chakra-ui/theme";

const Button: ComponentStyleConfig = {
    variants: {
        'outline': {
            fontWeight: '400',
            fontSize: '1.3em',
            textTransform: 'uppercase',
            borderStyle: 'solid',
            borderWidth: '2px',
            borderRadius: '300px',
            padding: '25',
        }
    },
};

export default Button