import {StyleFunctionProps} from "@chakra-ui/react";
import {mode} from "@chakra-ui/theme-tools";

const styles = {
    global: (props: StyleFunctionProps) => ({
        body: {
            fontFamily: 'body',
            color: mode('gray.700', 'whiteAlpha.900')(props),
            bg: mode('white', 'gray.800')(props),
            lineHeight: 'base',
            background: mode('white', '#1f2d3d')(props)
        },
    }),
};

export default styles;