import React, {ChangeEvent, useState} from "react";

import {Button, Input} from "@chakra-ui/react";

import {ViGroupDto} from "./api/dtos";

import ViGroupGauge from "./vi-group-gauge";

function AutomatedViGroupResult() {
    const [value, setValue] = useState<string>("");
    const [response, setResponse] = useState<ViGroupDto | null>(null);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        setValue(inputValue);
    }

    const onSubmit = async () => {
        if (value) {
            const parsedResponse: ViGroupDto = JSON.parse(value);

            setResponse(parsedResponse);
        }
    }

    if (!response) {
        return (
            <form>
                <Input value={value}
                       onChange={handleChange}
                       name="input"/>

                <Button name="submit" onClick={onSubmit} type="submit">Submit</Button>
            </form>
        );
    } else {
        return (
            <div id="diagnostic-results">
                <ViGroupGauge vi={response}/>
            </div>
        )
    }
}


export default AutomatedViGroupResult;
