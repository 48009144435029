import React from "react";

import {
    BarSeries,
    Category,
    ChartComponent,
    DataLabel,
    Inject,
    SeriesCollectionDirective,
    SeriesDirective
} from "@syncfusion/ej2-react-charts";

import {TpiGroupDetailDto} from "./api/dtos";

export default function TpiGroupChart(props: { tpi: TpiGroupDetailDto }) {
    return (
        <ChartComponent style={{textAlign: "center"}}
                        primaryXAxis={{
                            valueType: 'Category',
                            title: '',
                            majorGridLines: {width: 0},
                            labelStyle: {fontFamily: "AktivGrotesk"}
                        }}
                        primaryYAxis={{
                            labelFormat: '{value}%',
                            title: '',
                            edgeLabelPlacement: 'Shift',
                            lineStyle: {width: 0},
                            labelStyle: {fontFamily: "AktivGrotesk"},
                            minimum: 0,
                            maximum: 100
                        }}
                        chartArea={{border: {width: 0}}}>
            <Inject services={[BarSeries, DataLabel, Category]}/>
            <SeriesCollectionDirective>
                <SeriesDirective dataSource={props.tpi.base}
                                 xName='question'
                                 yName='answer'
                                 type='Bar'
                                 fill={"grey"}
                                 columnSpacing={0.1}
                                 width={5}
                                 animation={{enable: false}}>
                </SeriesDirective>
                <SeriesDirective dataSource={props.tpi.tpi}
                                 xName='question'
                                 yName='answer'
                                 type='Bar'
                                 fill={"#002060"}
                                 columnSpacing={0.1}
                                 width={5}
                                 animation={{enable: false}}>
                </SeriesDirective>
            </SeriesCollectionDirective>
        </ChartComponent>
    );
}
