import {StepsStyleConfig} from 'chakra-ui-steps';

import Button from "./button";

const Steps = {
    ...StepsStyleConfig,
    baseStyle: (props: any) => {
        return {
            ...StepsStyleConfig.baseStyle(props),
            stepIconContainer: {
                ...StepsStyleConfig.baseStyle(props).stepIconContainer,
                _activeStep: {
                    bg: "#E0E7EF",
                    borderColor: "#DA5F27",
                },
            },
        };
    },
};
const components = {
    Button,
    Steps: Steps,
}

export default components;
