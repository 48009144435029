import React, {ChangeEvent, useState} from "react";

import {Button, Input} from "@chakra-ui/react";

import {TpiTeamAssessmentGroupDto} from "./api/dtos";

import TpiTeamAssessmentGroupChart from "./tpi-team-assessment-group-chart";

function AutomatedTpiTeamAssessmentGroupResult() {
    const [value, setValue] = useState<string>("");
    const [response, setResponse] = useState<TpiTeamAssessmentGroupDto | null>(null);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        setValue(inputValue);
    }

    const onSubmit = async () => {
        if (value) {
            try {
                const parsedResponse: TpiTeamAssessmentGroupDto = JSON.parse(value);

                setResponse(parsedResponse);
            }
            catch (error) {
                console.log(error);
            }
        }
    }

    if (!response) {
        return (
            <form>
                <Input value={value}
                       onChange={handleChange}
                       name="input"/>

                <Button name="submit" onClick={onSubmit} type="submit">Submit</Button>
            </form>
        );
    } else {
        return (
            <div id="diagnostic-results">
                <TpiTeamAssessmentGroupChart tpi={response}/>
            </div>
        )
    }
}


export default AutomatedTpiTeamAssessmentGroupResult;
