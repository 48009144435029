import React from "react";

import {
    AxesDirective,
    AxisDirective,
    CircularGaugeComponent,
    PointerDirective,
    PointersDirective,
    RangeDirective,
    RangesDirective
} from "@syncfusion/ej2-react-circulargauge";

import {TpiCustomerDto} from "./api/dtos";

export default function TpiCustomerGauge(props: { tpi: TpiCustomerDto }) {
    return (
        <CircularGaugeComponent centerY="55%" id="text-pointer" background={"transparent"} style={{fontFamily: "AktivGrotesk"}}>
            <AxesDirective>
                <AxisDirective startAngle={270} endAngle={90} radius="120%" minimum={0} maximum={24} rangeGap={3}
                               majorTicks={{width: 0}}
                               lineStyle={{width: 0}}
                               minorTicks={{width: 0}}
                               labelStyle={{font: {size: "0px"}}}
                >
                    <PointersDirective>
                        <PointerDirective pointerWidth={10} radius="75%" needleStartWidth={1} needleEndWidth={1} value={props.tpi.base} color={"grey"}
                                          cap={{radius: 0}} animation={{enable: false}}
                        >
                        </PointerDirective>
                        <PointerDirective pointerWidth={10} radius="60%" needleStartWidth={1} needleEndWidth={1} value={props.tpi.tpi} color={"#002060"}
                                          cap={{radius: 0}} animation={{enable: false}}
                        >
                        </PointerDirective>
                        <PointerDirective radius="85%" type="Marker" value={2} text="TPI1" markerShape="Text"
                                          animation={{enable: false}} textStyle={{size: "18px", fontFamily: "inherit"}}
                        >
                        </PointerDirective>
                        <PointerDirective radius="85%" type="Marker" value={6} text="TPI2" markerShape="Text"
                                          animation={{enable: false}} textStyle={{size: "18px", fontFamily: "inherit"}}
                        >
                        </PointerDirective>
                        <PointerDirective radius="85%" type="Marker" value={10} text="TPI3" markerShape="Text"
                                          animation={{enable: false}} textStyle={{size: "18px", fontFamily: "inherit"}}
                        >
                        </PointerDirective>
                        <PointerDirective radius="85%" type="Marker" value={14} text="TPI4" markerShape="Text"
                                          animation={{enable: false}} textStyle={{size: "18px", fontFamily: "inherit"}}
                        >
                        </PointerDirective>
                        <PointerDirective radius="85%" type="Marker" value={18} text="TPI5" markerShape="Text"
                                          animation={{enable: false}} textStyle={{size: "18px", fontFamily: "inherit"}}
                        >
                        </PointerDirective>
                        <PointerDirective radius="85%" type="Marker" value={22} text="TPI6" markerShape="Text"
                                          animation={{enable: false}} textStyle={{size: "18px", fontFamily: "inherit"}}
                        >
                        </PointerDirective>
                    </PointersDirective>
                    <RangesDirective>
                        <RangeDirective start={0} end={4} radius="80%" startWidth={85} endWidth={85} color="#dd3800">
                        </RangeDirective>
                        <RangeDirective start={4.1} end={8} radius="80%" startWidth={85} endWidth={85} color="#ff4100">
                        </RangeDirective>
                        <RangeDirective start={8.1} end={12} radius="80%" startWidth={85} endWidth={85} color="#ffba00">
                        </RangeDirective>
                        <RangeDirective start={12.1} end={16} radius="80%" startWidth={85} endWidth={85} color="#ffdf10">
                        </RangeDirective>
                        <RangeDirective start={16.1} end={20} radius="80%" startWidth={85} endWidth={85} color="#8be724">
                        </RangeDirective>
                        <RangeDirective start={20.1} end={24} radius="80%" startWidth={85} endWidth={85} color="#64be00">
                        </RangeDirective>
                    </RangesDirective>
                </AxisDirective>
            </AxesDirective>
        </CircularGaugeComponent>
    );
}
