import React from "react";

import {useDispatch, useSelector} from "react-redux";

import {Answer, updateAnswer9} from "./questionnaire-slice";
import Question from "./question";
import {QuestionProps} from "./question-props";
import {RootState} from "./store";

function QuestionGroup9({prevAction, nextAction}: QuestionProps) {
    const questionnaire = useSelector((state: RootState) => state.questionnaire);
    const dispatch = useDispatch();

    const next = (answer: Answer) => {
        dispatch(updateAnswer9(answer));

        nextAction();
    }

    return (
        <Question category={"Outcomes"}
                  text={"Our team achieves exponential outcomes"}
                  whyImportantText={"Teams that view success as turning the handle and delivering transactional outcomes are targets for disruption in their industries. It takes the same time and effort to achieve 10X outcomes. Are you collectively focused on the right things?"}
                  whatBadLooksLikeText={["Saying “If it’s not broken, don’t fix it”", "Not prioritizing outcomes as a team", "Missing deadlines or not meeting demands on a frequent basis"]}
                  whatGreatLooksLikeText={["Winning teams consistently meet or exceed goals", "The norm is to innovate and create 10X outcomes, and we align together for the highest exponential return", "Running agile leadership sprints to assure we win together"]}
                  initialAnswer={questionnaire.answer9}
                  prevAction={prevAction}
                  nextAction={next}
        />
    );
}

export default QuestionGroup9;
