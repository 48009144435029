import {extendTheme, type ThemeConfig} from "@chakra-ui/react";

import * as foundations from "./foundations";

import * as styles from "./styles";
import components from "./components";

const config: ThemeConfig = {
    initialColorMode: "light",
    useSystemColorMode: false,
};

const theme = extendTheme({
    config,
    ...foundations,
    ...styles,
    components,
});

export default theme;