import React from "react";

import {useDispatch, useSelector} from "react-redux";

import {Answer, updateAnswer8} from "./questionnaire-slice";
import Question from "./question";
import {QuestionProps} from "./question-props";
import {RootState} from "./store";

function QuestionGroup8({prevAction, nextAction}: QuestionProps) {
    const questionnaire = useSelector((state: RootState) => state.questionnaire);
    const dispatch = useDispatch();

    const next = (answer: Answer) => {
        dispatch(updateAnswer8(answer));

        nextAction();
    }

    return (
        <Question category={"Resilience"}
                  text={"We all celebrate wins, making us a resilient team"}
                  whyImportantText={"Individual and team resilience is critical when things get tough, when things go wrong, or when something extraordinary happens that means we all need to dig deep to overcome a significant obstacle to our success. Is your team ready?"}
                  whatBadLooksLikeText={["Not acknowledging progress along a journey", "Only celebrating our own wins, and not the wins of our peers and colleagues", "Letting other team members’ energy be low because we feel great today"]}
                  whatGreatLooksLikeText={["Everyone encourages and celebrates success before we move on to the next activity", "Noticing when our peers and colleagues may be down and may need some support at the personal or professional level", "Owning energy together"]}
                  initialAnswer={questionnaire.answer8}
                  prevAction={prevAction}
                  nextAction={next}
        />
    );
}

export default QuestionGroup8;
