import React from "react";

import {useDispatch, useSelector} from "react-redux";

import {Answer, updateAnswer3} from "./questionnaire-slice";
import Question from "./question";
import {QuestionProps} from "./question-props";
import {RootState} from "./store";

function QuestionGroup3({prevAction, nextAction}: QuestionProps) {
    const questionnaire = useSelector((state: RootState) => state.questionnaire);
    const dispatch = useDispatch();

    const next = (answer: Answer) => {
        dispatch(updateAnswer3(answer));

        nextAction();
    }

    return (
        <Question category={"Candor"}
                  text={"We all speak courageous truth"}
                  whyImportantText={"Would you fly on a plane or undertake medical surgery if the provider team members didn’t speak honestly with one another? Yet this is the most typical state in teams across the world today."}
                  whatBadLooksLikeText={["Side bar texts or 1-2-1 messages in meetings", "Being silent and not sharing your view", "Not speaking up in meetings, even when you have critical information to share"]}
                  whatGreatLooksLikeText={["Speaking honestly in every meeting", "Sharing a contrarian view because it’s critical", "Challenging a colleague because you care about their success"]}
                  initialAnswer={questionnaire.answer3}
                  prevAction={prevAction}
                  nextAction={next}
        />
    );
}

export default QuestionGroup3;
