import React, {useEffect} from "react";

import {Box, Container, useMediaQuery, VStack} from "@chakra-ui/react";
import {Step, Steps, useSteps} from 'chakra-ui-steps';

import QuestionCustomerPersonalInfo from "./question-customer-personal-info";
import QuestionCustomer1 from "./question-customer-1";
import QuestionCustomer2 from "./question-customer-2";
import QuestionCustomer3 from "./question-customer-3";
import QuestionCustomer4 from "./question-customer-4";
import QuestionCustomer5 from "./question-customer-5";
import QuestionCustomer6 from "./question-customer-6";
import QuestionCustomer7 from "./question-customer-7";
import QuestionCustomer8 from "./question-customer-8";
import QuestionCustomer9 from "./question-customer-9";
import QuestionCustomer10 from "./question-customer-10";
import QuestionCustomer11 from "./question-customer-11";
import QuestionCustomer12 from "./question-customer-12";
import DiagnosticCustomerSummary from "./diagnostic-customer-summary";
import ReactGA from "react-ga4";

const QuestionnaireCustomer = () => {
    const {nextStep, prevStep, activeStep} = useSteps({
        initialStep: 0,
    });

    useEffect(() => {
        const currentStep = activeStep + 1;

        const page = currentStep === 0 ? "Customer Diagnostic" : "Customer Diagnostic - " + currentStep;

        ReactGA.send({
            hitType: "pageview",
            page: page,
            title: page
        });
    }, [activeStep]);

    const steps = [
        {label: 'Integrity', content: <QuestionCustomer1 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Humanity', content: <QuestionCustomer2 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Candor', content: <QuestionCustomer3 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Cohesion', content: <QuestionCustomer4 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Ecosystem', content: <QuestionCustomer5 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Accountability', content: <QuestionCustomer6 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Learning', content: <QuestionCustomer7 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Resilience', content: <QuestionCustomer8 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Outcomes', content: <QuestionCustomer9 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Geography', content: <QuestionCustomer10 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Care', content: <QuestionCustomer11 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Cohesion', content: <QuestionCustomer12 prevAction={prevStep} nextAction={nextStep}/>},
        {label: 'Personal Details', content: <QuestionCustomerPersonalInfo nextAction={nextStep}/>},
        {label: 'Results', content: <DiagnosticCustomerSummary/>},
    ];

    const [isLargerThan800] = useMediaQuery('(min-width: 800px)')

    return (
        <VStack spacing={7}>
            <Container minW={['1xl', '1xl', '3xl', '4xl', '6xl']}>
                <Steps activeStep={activeStep} size='md' mt={5} colorScheme={'orange'} responsive={false}
                       sx={{
                           display: isLargerThan800 ? "flex" : "none"
                       }}>
                    {steps.map(({label, content}, index) =>
                        (
                            <Step label={""} key={index}>
                                <Box m={5}>
                                    {content}
                                </Box>
                            </Step>
                        ))}
                </Steps>
            </Container>
        </VStack>
    );
};

export default QuestionnaireCustomer;
