import React from "react";

import {useDispatch, useSelector} from "react-redux";

import {Answer, updateAnswer5} from "./questionnaire-slice";
import Question from "./question";
import {QuestionProps} from "./question-props";
import {RootState} from "./store";

function QuestionCustomer5({prevAction, nextAction}: QuestionProps) {
    const questionnaire = useSelector((state: RootState) => state.questionnaire);
    const dispatch = useDispatch();

    const next = (answer: Answer) => {
        dispatch(updateAnswer5(answer));

        nextAction();
    }

    return (
        <Question category={"Ecosystem"}
                  text={"We all build broad networks to accelerate our success"}
                  whyImportantText={"Success comes not just from your immediate team, but from curating and nurturing a broad professional network that is aligned – and committed – to your success. Has your team built a powerful ecosystem to fast track your success?"}
                  whatBadLooksLikeText={["Only seeking input from the same people", "Doing the same things over again and expecting different results", "Leaving your team’s ecosystem to chance"]}
                  whatGreatLooksLikeText={["Seeking external expert perspectives", "Ideating, mapping, and prioritizing external stakeholders who are critical to your success", "Being vulnerable and building co-advocate relationships that are mutually beneficial"]}
                  initialAnswer={questionnaire.answer5}
                  prevAction={prevAction}
                  nextAction={next}
        />
    );
}

export default QuestionCustomer5;
