import React from 'react';

import {Provider} from "react-redux";

import ReactDOM from 'react-dom/client';

import {
    ChakraProvider
} from "@chakra-ui/react";

import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
// import {ReactQueryDevtools} from "@tanstack/react-query-devtools";

import reportWebVitals from './reportWebVitals';

import App from './app';

import theme from "./theme";

import {store} from "./store";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
        }
    }
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    {/*<Container minW='100%' p={0}>*/}
                        <App/>
                    {/*</Container>*/}
                    {/*<ReactQueryDevtools initialIsOpen={false}/>*/}
                </QueryClientProvider>
            </Provider>
        </ChakraProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
