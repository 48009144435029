import axios from "axios";

import {CustomerDiagnoseRequestDto, GroupDiagnoseRequestDto} from "./dtos";

const baseUrl = "https://redefine-winning-apim.azure-api.net/diagnostic";
// const baseUrl = "http://localhost:7071/api";

const heathEndpoint = "/health";

const customerDiagnoseEndpoint = "/customer-diagnose";

const groupDiagnoseEndpoint = "/group-diagnose";

const apiClient = axios.create(
    {
        baseURL: baseUrl,
        headers: {
            "Content-type": "application/json",
        },
    });

const customerDiagnose = async (request: CustomerDiagnoseRequestDto): Promise<BlobPart> => {
    const json = JSON.stringify(request);

    return apiClient
        .get(heathEndpoint)
        .then(_ => apiClient
            .post(customerDiagnoseEndpoint, json)
            .then((response => response.data)));
}

const groupDiagnose = async (request: GroupDiagnoseRequestDto): Promise<BlobPart> => {
    const json = JSON.stringify(request);

    return apiClient
        .get(heathEndpoint)
        .then(_ => apiClient
            .post(groupDiagnoseEndpoint, json)
            .then((response => response.data)));
}

const DataService = {
    customerDiagnose,
    groupDiagnose
}

export default DataService;
