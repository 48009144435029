import React from "react";

import {useDispatch, useSelector} from "react-redux";

import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Center,
    Heading,
    HStack,
    VStack
} from "@chakra-ui/react";

import {Formik} from "formik";
import {
    InputControl,
    SubmitButton
} from "formik-chakra-ui";
import * as Yup from "yup";

import {RootState} from "./store";
import {updateInfo} from "./questionnaire-slice";

type StartProps = {
    nextAction: () => void
}

function QuestionCustomerPersonalInfo({nextAction}: StartProps) {
    const info = useSelector((state: RootState) => state.questionnaire.info)
    const dispatch = useDispatch()

    const initialValues = {
        "given-name": info.firstName,
        "family-name": info.lastName,
        "organization": info.company,
        email: info.email,
        "tel": info.phone,
    };

    const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

    const validationSchema = Yup.object({
        "given-name": Yup.string().required("First Name is a required field"),
        "family-name": Yup.string().required("Last Name is a required field"),
        "organization": Yup.string().required("Company is a required field"),
        email: Yup.string().email().required("Email is a required field to receive the results"),
        "tel": Yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Phone is a required field")
    });

    const onSubmit = (values: typeof initialValues) => {
        const info = {
            firstName: values["given-name"],
            lastName: values["family-name"],
            company: values["organization"],
            email: values.email,
            phone: values["tel"]
        }

        dispatch(updateInfo(info))

        nextAction();
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({handleSubmit}) => (
                <Card>
                    <CardHeader>
                        <Center>
                            <Heading size={"xl"} mt={7}>Personal Information</Heading>
                        </Center>
                    </CardHeader>

                    <CardBody>
                        <VStack spacing={5}
                                as="form"
                                onSubmit={handleSubmit as any}>
                            <InputControl name="given-name" label="First Name"/>
                            <InputControl name="family-name" label="Last Name"/>
                            <InputControl name="organization" label="Company"/>
                            <InputControl name="email" label="Email (a valid email address is required to receive the diagnostic report we will send to you)"/>
                            <InputControl name="tel" label="Phone"/>
                        </VStack>
                    </CardBody>

                    <CardFooter justify={'center'}>
                        <HStack justify={'center'}>
                            <SubmitButton onClick={() => handleSubmit()} variant={'outline'} colorScheme={'orange'}>Next</SubmitButton>
                        </HStack>
                    </CardFooter>
                </Card>
            )}
        </Formik>
    );
}

export default QuestionCustomerPersonalInfo;
