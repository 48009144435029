import React from "react";

import {useDispatch, useSelector} from "react-redux";

import {Answer, updateAnswer12} from "./questionnaire-slice";
import Question from "./question";
import {QuestionProps} from "./question-props";
import {RootState} from "./store";

function QuestionGroup12({prevAction, nextAction}: QuestionProps) {
    const questionnaire = useSelector((state: RootState) => state.questionnaire);
    const dispatch = useDispatch()

    const next = (answer: Answer) => {
        dispatch(updateAnswer12(answer));

        nextAction();
    }

    return (
        <Question category={"Potential"}
                  text={"Our team achieves our full potential"}
                  whyImportantText={"Working hard is expected, but it’s heartbreaking to see teams of people working hard and not achieving their full potential. What makes that worse? People not enjoying what they do, day in, day out."}
                  whatBadLooksLikeText={["Noticing inefficiencies in the way the team works and/or withholding things from one another", "Not discussing the ‘elephants in the room’", "Not enjoying high quality time with your peers and broader organizational network"]}
                  whatGreatLooksLikeText={["Successfully applying teaming competencies to unlock the latent potential of the team", "Enjoying every opportunity to partner together", "Achieving our full potential as a team"]}
                  initialAnswer={questionnaire.answer12}
                  prevAction={prevAction}
                  nextAction={next}
        />
    );
}

export default QuestionGroup12;
