import React from "react";

import {useDispatch, useSelector} from "react-redux";

import {Answer, updateAnswer4} from "./questionnaire-slice";
import Question from "./question";
import {QuestionProps} from "./question-props";
import {RootState} from "./store";

function QuestionCustomer4({prevAction, nextAction}: QuestionProps) {
    const questionnaire = useSelector((state: RootState) => state.questionnaire);
    const dispatch = useDispatch();

    const next = (answer: Answer) => {
        dispatch(updateAnswer4(answer));

        nextAction();
    }

    return (
        <Question category={"Co-Creation"}
                  text={"We all co-create together, rather than in silos"}
                  whyImportantText={"It’s proven time and time again that a team offers better answers than an individual. Does your team drive significant, tangible value from the interdependencies that exist within the team?"}
                  whatBadLooksLikeText={["Working in silos", "Putting individual value or gain above team success", "Doing some work and then handing it off, but not owning success together"]}
                  whatGreatLooksLikeText={["Everyone aligned around the same mission and contributing to our shared success", "Harnessing the strengths of other team members because we’re stronger together", "Not letting structure and hierarchy slow us down"]}
                  initialAnswer={questionnaire.answer4}
                  prevAction={prevAction}
                  nextAction={next}
        />
    );
}

export default QuestionCustomer4;
