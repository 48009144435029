import React from "react";

import {
    AxesDirective,
    AxisDirective,
    IAxisLabelRenderEventArgs,
    LinearGaugeComponent,
    PointerDirective,
    PointersDirective
} from "@syncfusion/ej2-react-lineargauge";

import {Vi, ViCustomerDto} from "./api/dtos";

const axisLabelRender = (args: IAxisLabelRenderEventArgs): void => {
    if (args.text === "0.5")
        args.text = "Low";
    else if (args.text === "0.75")
        args.text = "Medium";
    else if (args.text === "1")
        args.text = "High";
    else
        args.text = "";
}

const getVi = (vi: Vi): number => {
    if (vi === "Low") {
        return 0.5;
    } else if (vi === "Medium") {
        return 0.75;
    } else if (vi === "High") {
        return 1;
    }

    return 0;
}

export default function ViCustomerGauge(props: { vi: ViCustomerDto }) {
    const baseBand = getVi(props.vi.base);
    const viBand = getVi(props.vi.vi);

    return (
        <LinearGaugeComponent axisLabelRender={axisLabelRender} orientation="Horizontal"
                              width="550px" height="150px" background={"transparent"} style={{fontFamily: "AktivGrotesk"}}>
            <AxesDirective>
                <AxisDirective line={{width: 5}} minorTicks={{interval: 0.25, height: 0}} majorTicks={{interval: 0.25, height: 0}}
                               labelStyle={{offset: 10, font: {size: "18px", fontFamily: "inherit"}}}
                               minimum={0.5} maximum={1} opposedPosition={true}>
                    <PointersDirective>
                        <PointerDirective width={30} height={30} value={1} color={"#ff4100"} placement="Near" markerType="Circle" offset={15}>
                        </PointerDirective>
                        <PointerDirective width={30} height={30} value={0.75} color={"#ffba00"} placement="Near" markerType="Circle" offset={15}>
                        </PointerDirective>
                        <PointerDirective width={30} height={30} value={0.5} color={"#8be724"} placement="Near" markerType="Circle" offset={15}>
                        </PointerDirective>
                        <PointerDirective value={baseBand} placement='Near' height={35} width={45} offset={40} markerType='InvertedArrow' color={"grey"} />
                        <PointerDirective value={viBand} placement='Near' height={35} width={45} offset={-30} markerType='Arrow' color={"#002060"} />
                    </PointersDirective>
                </AxisDirective>
            </AxesDirective>
        </LinearGaugeComponent>
    );
}
