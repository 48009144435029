import React from "react";

import {
    AnnotationDirective,
    Annotations, AnnotationsDirective,
    AxesDirective,
    AxisDirective,
    IAxisLabelRenderEventArgs,
    LinearGaugeComponent,
    PointerDirective,
    PointersDirective, RangeDirective, RangesDirective
} from "@syncfusion/ej2-react-lineargauge";

import {TeqCustomerDto} from "./api/dtos";
import {Inject} from "@syncfusion/ej2-react-circulargauge";
import {Heading} from "@chakra-ui/react";

const axisLabelRender = (args: IAxisLabelRenderEventArgs): void => {
    console.log(args.text);
    if (args.text === "70")
        args.text = "A";
    else if (args.text === "50")
        args.text = "B";
    else if (args.text === "30")
        args.text = "C";
    else if (args.text === "10")
        args.text = "D";
    else
        args.text = "";
}

const teqToNumber = (teq: string): number => {
    console.log(teq);
    switch (teq) {
        case "A":
            return 80;
        case "B":
            return 60;
        case "C":
            return 40;
        case "D":
            return 20;
        default:
            return 0;
    }
}

export default function TeqCustomerGauge(props: { teq: TeqCustomerDto }) {
    const value = teqToNumber(props.teq.teq);

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Heading size={'4xl'} color={"grey"}>{props.teq.base}</Heading>
            <LinearGaugeComponent axisLabelRender={axisLabelRender} background={'transparent'} orientation='Horizontal' width='500px' height='320px'
                                  container={{width: 116, type: 'RoundedRectangle', border: {width: 5}}} style={{fontFamily: "AktivGrotesk"}}>
                <Inject services={[Annotations]}/>
                <AxesDirective>
                    <AxisDirective minimum={0} maximum={80} line={{width: 0}} minorTicks={{interval: 5, height: 0}} majorTicks={{interval: 10, height: 0}} labelStyle={{offset: -10, font: {size: "18px", fontFamily: "inherit"}}}>
                        <PointersDirective>
                            <PointerDirective width={0}>
                            </PointerDirective>
                        </PointersDirective>
                        <RangesDirective>
                            {value > 0 && <RangeDirective start={1} end={19} startWidth={104} endWidth={104} color='#ff4100' offset={110}>
                            </RangeDirective>}
                            {value > 20 && <RangeDirective start={21} end={39} startWidth={104} endWidth={104} color='#ffba00' offset={110}>
                            </RangeDirective>}
                            {value > 40 && <RangeDirective start={41} end={59} startWidth={104} endWidth={104} color='#ffdf10' offset={110}>
                            </RangeDirective>}
                            {value > 60 && <RangeDirective start={61} end={79} startWidth={104} endWidth={104} color='#8be724' offset={110}>
                            </RangeDirective>}
                        </RangesDirective>
                    </AxisDirective>
                </AxesDirective>
                <AnnotationsDirective>
                    <AnnotationDirective content='<div style="width: 16px;height: 74px;border: 5px solid #bfbfbf;margin-left:210px;margin-top:115px;border-radius: 6px;" />'
                                         axisIndex={0}
                                         axisValue={60}
                                         x={0} zIndex='1'
                                         y={0}>
                    </AnnotationDirective>
                </AnnotationsDirective>
            </LinearGaugeComponent>
            <Heading size={'4xl'} color={"#002060"}>{props.teq.teq}</Heading>
        </div>
    );
}
