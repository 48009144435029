const blue = {
    200: '#32c2ec',
    500: '#32c2ec',
};

const orange = {
    500: '#DA5F27',
    600: '#DA5F27',
};

const colors = {
    blue,
    orange
}

export default colors;