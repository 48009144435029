import React from "react";

import {
    AxesDirective,
    AxisDirective,
    CircularGaugeComponent, Gradient, Inject,
    PointerDirective,
    PointersDirective,
    RangeDirective,
    RangesDirective
} from "@syncfusion/ej2-react-circulargauge";

import {TpiGroupDto} from "./api/dtos";
import {LinearGradientModel} from "@syncfusion/ej2-circulargauge/src/circular-gauge/axes/gradient-model";

export default function TpiGroupGauge(props: { tpi: TpiGroupDto }) {

    const rangeLinearGradient: LinearGradientModel = {
        startValue: "0%",
        endValue: "100%",
        colorStop: [
            {color: "#dd3800", offset: "0%", opacity: 1},
            {color: "#ff4100", offset: "25%", opacity: 1},
            {color: "#ffba00", offset: "50%", opacity: 1},
            {color: "#ffdf10", offset: "75%", opacity: 1},
            {color: "#64be00", offset: "100%", opacity: 1}
        ]
    };

    const base = props.tpi.base;
    const tpi = props.tpi.tpi;

    return (
        <CircularGaugeComponent centerY="55%" id="text-pointer" background={"transparent"} style={{fontFamily: "AktivGrotesk"}}>
            <Inject services={[Gradient]}/>
            <AxesDirective>
                <AxisDirective startAngle={270} endAngle={90} radius="120%" minimum={0} maximum={100} rangeGap={3}
                               majorTicks={{width: 0}}
                               lineStyle={{width: 0}}
                               minorTicks={{width: 0}}
                               labelStyle={{font: {size: "0px"}}}
                >
                    <PointersDirective>
                        <PointerDirective pointerWidth={20} radius="51%" value={base}
                                          type={"RangeBar"} animation={{enable: false}} color={'grey'}
                        >
                        </PointerDirective>
                        <PointerDirective pointerWidth={20} radius="58%" value={tpi}
                                          type={"RangeBar"} animation={{enable: false}} color={'#002060'}
                        >
                        </PointerDirective>
                        <PointerDirective radius="70%" type="Marker" value={8.33} text="TPI1" markerShape="Text"
                                          animation={{enable: false}} textStyle={{size: "18px", fontFamily: "inherit"}}
                        >
                        </PointerDirective>
                        <PointerDirective radius="70%" type="Marker" value={25} text="TPI2" markerShape="Text"
                                          animation={{enable: false}} textStyle={{size: "18px", fontFamily: "inherit"}}
                        >
                        </PointerDirective>
                        <PointerDirective radius="70%" type="Marker" value={41.66} text="TPI3" markerShape="Text"
                                          animation={{enable: false}} textStyle={{size: "18px", fontFamily: "inherit"}}
                        >
                        </PointerDirective>
                        <PointerDirective radius="70%" type="Marker" value={58.33} text="TPI4" markerShape="Text"
                                          animation={{enable: false}} textStyle={{size: "18px", fontFamily: "inherit"}}
                        >
                        </PointerDirective>
                        <PointerDirective radius="70%" type="Marker" value={75} text="TPI5" markerShape="Text"
                                          animation={{enable: false}} textStyle={{size: "18px", fontFamily: "inherit"}}
                        >
                        </PointerDirective>
                        <PointerDirective radius="70%" type="Marker" value={91.66} text="TPI6" markerShape="Text"
                                          animation={{enable: false}} textStyle={{size: "18px", fontFamily: "inherit"}}
                        >
                        </PointerDirective>
                    </PointersDirective>
                    <RangesDirective>
                        <RangeDirective start={0} end={100} radius="65%" startWidth={56} endWidth={56} linearGradient={rangeLinearGradient}>
                        </RangeDirective>
                    </RangesDirective>
                </AxisDirective>
            </AxesDirective>
        </CircularGaugeComponent>
    );
}
